.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  position: fixed;
  top: 0;
  padding-left: 3%;
  padding-right: 3%;
  width: 100%;
  height: 8vh;
  z-index: 100;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  background-color: #f6f9fc;
  align-items: center;
}

.logout-button {
  background-color: #b64958;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.logout-button:hover {
  background-color: #9a3d47;
}
