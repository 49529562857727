/* Define custom styles for the MDBTabsItem components */
.custom-tab-item {
  background-color: #ffffff;
  /* Set the default background color */
  color: #000;
  /* Set the default text color */
}

.App-logo {
  width: 100%;
  height: auto;
}

.custom-tab-item .nav-link.active {
  background-color: #b64958 !important;
  color: #ffffff !important;
}

.custom-tab-item .nav-link {
  color: #000 !important;
  background-color: #e3e3e3 !important;
}

.gradient-background {
  background-image: linear-gradient(to bottom, white 200px, #49b6a7 700px);
}
.nav-justified .nav-item .nav-link {
  width: 95%;
}

.nav-justified .nav-item {
  background: transparent;
  cursor: auto !important;
  display: flex;
  justify-content: center;
}
