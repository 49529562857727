.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Add the box-shadow property */
  max-width: 350px;
  height: auto;
}
.modal-content2 {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Add the box-shadow property */
  max-width: 400px;
  height: auto;
}

.close {
  float: right;
  cursor: pointer;
}
.container1 {
  margin-top: 15%;
  max-width: 100%;
}

h1 {
  text-align: center;
}

.user-list {
  list-style: none;
  padding: 0;
}

.user-item {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.2s;
}

.user-item2 {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  list-style-type: none;
}

.user-item:hover {
  background-color: #f7f7f7;
}

.admin-button {
  margin-left: 10px;
}

.modal-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #b64958;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
  margin-left: 5px;
}
.modal-button2 {
  margin-top: 10px;
  margin-left: 5px;
  padding: 8px 16px;
  background-color: #838383;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
}
.modal-button3 {
  margin-top: 10px;
  margin-left: 5px;
  padding: 8px 16px;
  background-color: #838383;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-button:hover {
  background-color: #b64958;
}

.search-bar {
  width: 80%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.nav-bar1 {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #f0f0f0;
  position: fixed;
  top: 0;
  padding-left: 3%;
  padding-right: 3%;
  width: 100%;
  height: 8vh;
  z-index: 100;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  background-color: #f6f9fc;
  align-items: center;
}

.button {
  background-color: #b64958;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.button:hover {
  background-color: #9a3d47;
}

.button-container {
  display: flex;
  width: 100%;
}

.searchbarContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
}
