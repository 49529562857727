body {
  background: #f6f9fc;
  font-family: "Open Sans", sans-serif;
  color: #525f7f;
}

html {
  background: #b8fff7 !important;
}

.user-profile-page {
  font-family: Arial, sans-serif;
  max-width: 100vw;
  background-image: linear-gradient(to bottom, #50dcc9 475px, #b8fff7 1200px);
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  position: fixed;
  top: 0;
  padding-left: 3%;
  padding-right: 3%;
  width: 100%;
  height: 8vh;
  z-index: 100;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  background-color: #f6f9fc;
  align-items: center;
}

.logout-button {
  background-color: #b64958;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.logout-button:hover {
  background-color: #9a3d47;
}

h2 {
  margin: 5%;
  text-align: center;
  font-size: 2rem;
  font-weight: 100;
}

.progressBar {
  width: 75%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  background: #fff;
  border-radius: 65px 17px 17px 65px;
  box-shadow: #2daa9150 0px 2px 8px 0px;
  padding: 15px 25px 15px 15px;
  gap: 25px;
}

.timeline {
  width: 100%;
  margin: 5% auto;
  top: 5%;
  margin-top: 6vh;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.prog-content {
  width: 100%;
  margin-right: 3%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.prog-content h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1e826e;
  line-height: 30px;
}

.prog-content p {
  font-size: 1.2rem;
  color: #a4a4a4;
  font-weight: 600;
  margin-bottom: 10px;
}

.timeline__event {
  background: #fff;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 35px 65px 65px 35px !important;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  -webkit-border-radius: 35px 65px 65px 35px !important;
  -moz-border-radius: 35px 65px 65px 35px !important;
  -ms-border-radius: 35px 65px 65px 35px !important;
  -o-border-radius: 35px 65px 65px 35px !important;
}
.timeline__event__title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #b64958;
  letter-spacing: 1.5px;
}
.timeline__event__content {
  padding: 20px;
}
.timeline__event__date {
  color: #ffafbc;
  font-size: 1.5rem;
  font-weight: 600;
  white-space: nowrap;
}
.timeline__event__icon {
  border-radius: 8px 0 0 8px;
  background: #b64958;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 40%;
  font-size: 2rem;
  color: #b64958;
  padding: 10px;
}
.timeline__event__icon i {
  position: absolute;
  top: 50%;
  left: -65px;
  font-size: 2.5rem;
  transform: translateY(-50%);
}
.timeline__event__description {
  flex-basis: 60%;
}

.timeline__event--achieved {
  color: #24b47e; /* soothing green color */
  background: #24b47e; /* soothing green color */
}

.timeline__event--unachieved {
  color: #858585; /* grey color */
  background: #858585; /* grey color */
}

.timeline__event--achieved .timeline__event__icon {
  background: #24b47e; /* soothing green color */
}

.timeline__event--achieved .timeline__event__title,
.timeline__event--achieved .timeline__event__date {
  color: #24b47e; /* soothing green color */
}

.timeline__event--unachieved .timeline__event__icon {
  background: #d3d3d3; /* grey color */
}

.timeline__event--unachieved .timeline__event__title,
.timeline__event--unachieved .timeline__event__date {
  color: #d3d3d3; /* grey color */
}

.timeline__event:last-child:after {
  content: none; /* remove content for the last event */
}

.firstTimeLineBlock {
  margin-top: 6vh !important;
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
  }
  .timeline__event__icon {
    border-radius: 4px 4px 0 0;
  }

  .circle {
    width: 100%;
    aspect-ratio: 16 / 3;
    border-radius: 30px;
  }

  .timeline__event {
    background: #fff;
    margin-bottom: 20px;
    position: relative;
    align-items: center;
    display: flex;
    margin: 20px 0;
    border-radius: 35px 35px 35px 35px !important;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
      0 18px 36px -18px rgba(0, 0, 0, 0.3),
      0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    -webkit-border-radius: 35px 35px 35px 35px !important;
    -moz-border-radius: 35px 35px 35px 35px !important;
    -ms-border-radius: 35px 35px 35px 35px !important;
    -o-border-radius: 35px 35px 35px 35px !important;
  }
}
